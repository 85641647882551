import React from 'react'

import '../../../styles/About/Route/Intro.scss'
const Intro = () => {
    return (
        <>
            <div className="about-intro">
                <div className="about-intro__container">
                    <div className="about-intro__header">
                        <h2 className="about-intro__number">0<span>1</span></h2>
                        <h1 data-aos='fade-down' data-aos-offset="0">H<span>ello</span>.</h1>
                    </div>
                    <div className="about-intro__description">
                        <p data-aos='fade-right' >I am <span>Sameer Jakkali....</span></p>
                         <p data-aos='fade-right' data-aos-delay='200'>I am passionate about staying at the forefront of technology trends and driving digital innovation for my clients. If you are seeking expertise in IT consulting, process automation, data analytics, or project management.</p>
                        <p data-aos='fade-right' data-aos-delay='400'>As an Experienced Senior Consultant in the Information Technology and Services industry, I am passionate about driving digital transformation through the strategic application of technology. With a strong foundation in Power Canvas App, Model Driven app, Power Automate, Azure Logic Apps, RPA, Power BI, SharePoint, SQL Database, Dataverse, and Project Management, I have consistently delivered high-impact solutions to clients.</p>
                        <p data-aos='fade-right' data-aos-delay='600'>Project Management Excellence: I have successfully led and managed diverse IT projects, overseeing the entire project lifecycle from concept to implementation. My knack for effective project management has resulted in streamlined processes, cost savings, and improved productivity.</p>
                        <p data-aos='fade-right' data-aos-delay='800'>Automation & Efficiency: I specialize in leveraging technology to automate repetitive tasks and streamline business processes. This includes expertise in Power Automate and Robotic Process Automation (RPA), enabling organizations to operate more efficiently. 
                            <br/> <br/> Data Insights: My proficiency in Power BI and SQL Database empowers organizations to extract actionable insights from their data, facilitating data-driven decision-making.
                            <br/> <br/>Collaborative Solutions: I have a demonstrated history of collaborating with cross-functional teams and clients to develop tailored solutions that address unique business challenges.</p>
                       

                        <div className="about-intro__emoji">:&nbsp;)</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro