import React, { useEffect } from "react";

// Importing TagCloud for  3D Rotating Text Sphere
import TagCloud from "TagCloud";

import "../../styles/Skill/Skill.scss";

const Skill = () => {
  // Animation settings for Text Cloud
  useEffect(() => {
    const container = ".tagcloud";
    let radii;

    // If 3D Text Sphere is not showing up after deployment remove the return (i.e, return() =>{}) function from below code
    return () => {
      const texts = [
        "Power Apps",
        "Power Automate",
        "Power BI",
        "Power Virtual Agent",
        "Canvas App",
        "Model App",
        "RPA",
        "Dataverse",
        "MySQL",
        "SharePoint",
        "Azure API Management",
        "Azure Logic Apps",
        "Azure App Services",
        "Azure DevOps",
        "JavaScript",
        "PCF",
      ];

      // Decrasing 'radius' value for small screen devices
      function radiusValue() {
        if (window.screen.width <= 778) {
          radii = 150;
        } else {
          radii = 290;
        }
        return radii;
      }

      const options = {
        radius: radiusValue(),
        maxSpeed: "normal",
        initSpeed: "normal",
        keep: true,
      };

      TagCloud(container, texts, options);
    };
  }, []);
  return (
    <>
      <div className="skill">
        <div className="skill__main-container">
          <div className="skill__header" id="skill__heading">
            <h1>
              <span
                data-aos="fade-down"
                data-aos-delay="0"
                data-aos-offset="200"
              >
                S
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-offset="200"
              >
                K
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-offset="200"
              >
                I
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-offset="200"
              >
                L
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="800"
                data-aos-offset="200"
              >
                L
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="1000"
                data-aos-offset="200"
              >
                S
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="1200"
                data-aos-offset="200"
              >
                .
              </span>
            </h1>
          </div>
          <div className="skill__inner-container">
            <div className="skill__info">
              <div className="skill__details-container">
                <div className="skill__details">
                  <h4>Power Platform</h4>
                  <ul>
                    <li>
                      <span>Canvas App</span>
                    </li>
                    <li>
                      <span>Model Driven App</span>
                    </li>
                    <li>
                      <span>Power Automate</span>
                    </li>
                    <li>
                      <span>Power Automate Desktop</span>
                    </li>
                    <li>
                      <span>Power Virtual Agent</span>
                    </li>
                    <li>
                      <span>RPA</span>
                    </li>
                    <li>
                      <span>PCF</span>
                    </li>
                    <li>
                      <span>Dataverse</span>
                    </li>
                  </ul>
                </div>
                <div className="skill__details">
                  <h4>Azure</h4>
                  <ul>
                    <li>
                      <span>
                      Azure API Management
                      </span>
                    </li>
                    <li>
                      <span>Azure Logic Apps</span>
                    </li>
                    <li>
                      <span>Azure App Services</span>
                    </li>
                    <li>
                      <span>Azure DevOps</span>
                    </li>
                    <li>
                      <span>Azure SQL</span>
                    </li>
                  </ul>
                </div>
                <div className="skill__details">
                  <h4>SharePoint</h4>
                  <ul>
                    <li>
                      <span>SharePoint Custom Scripts</span>
                    </li>
                    <li>
                      <span>SharePoint API</span>
                    </li>
                    <li>
                      <span>SharePoint List & Document Library Customisation</span>
                    </li>
                  </ul>
                </div>
                <div className="skill__details">
                  <h4>Query/Programming</h4>
                  <ul>
                    <li>
                      <span>Power FX</span>
                    </li>
                    <li>
                      <span>DAX</span>
                    </li>
                    <li>
                      <span>Power Query/MQuery</span>
                    </li>
                    <li>
                      <span>JSON</span>
                    </li>
                    <li>
                      <span>SQL</span>
                    </li>
                    <li>
                      <span>JavaScript</span>
                    </li>
                    <li>
                      <span>TypeScript</span>
                    </li>
                    <li>
                      <span>React JS</span>
                    </li>
                  </ul>
                </div>
                
              </div>
            </div>

            {/* 3D Text Cloud  */}
            <div className="skill__cloud">
              <div
                className="tagcloud"
                data-aos="zoom-in-up"
                data-aos-offset="200"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skill;
