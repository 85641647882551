import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faSquareCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faGithub,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../../styles/Contact/Contact.scss";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading indicator

    try {
      const response = await fetch("https://prod-01.centralindia.logic.azure.com:443/workflows/71b70ae3387d441b9c4b485f6ca8ec13/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=syhCsAYcV5mQr0Gt3286HxwG3Fe5rzrVirz2BBj8i5Q", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData), // Send form data
      });

      if (response.ok) {
        const data = await response.json();
        setResponse("Your message was sent successfully!");
      } else {
        setResponse("There was an issue sending your message.");
      }
    } catch (error) {
      console.error("Error:", error);
      setResponse("Failed to send message. Please try again later.");
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  return (
    <>
      <div className="contact" id="contact">
        <div className="contact__header">
          <h1>
            {/* Your animated contact header */}
          </h1>
        </div>
        <div className="contact__container">
          <div className="contact__details">
            <div className="contact__form" data-aos="fade-up">
              <form name="contact" method="post" onSubmit={handleSubmit}>
                <p className="contact__form-group">
                  <label htmlFor="name">Name: </label>
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    placeholder="Enter name"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </p>
                <p className="contact__form-group">
                  <label htmlFor="email">Email: </label>
                  <input
                    type="email"
                    name="email"
                    autoComplete="off"
                    placeholder="Enter email"
                    required
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </p>
                <p className="contact__form-group">
                  <label htmlFor="message">Concern: </label>
                  <textarea
                    name="message"
                    id="contact__message"
                    cols="20"
                    rows="5"
                    placeholder="Type your message"
                    required
                    value={formData.message}
                    onChange={handleInputChange}
                  ></textarea>
                </p>
                <button type="submit" className="contact__send-btn">
                  {loading ? "Sending..." : "Send"}
                </button>
              </form>
              {/* Display response message */}
              {response && <p className="contact__response">{response}</p>}
            </div>
          </div>
          <div className="contact__info" data-aos="fade-up">
            <h3 className="contact__info-title">Let's Connect</h3>
            <div className="contact__info-details">
              <ul>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} size="2x" />
                  <p>
                    <span>Address:&nbsp;&nbsp;</span>Hubli, Karnataka, India 580024
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} size="2x" />
                  <p>
                    <span>Phone:</span>&nbsp;&nbsp;(+91) 9916657739
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faEnvelope} size="2x" />
                  <p>
                    <span>Email:&nbsp;&nbsp;</span>sameerjakkali@gmail.com
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faSquareCheck} size="2x" />
                  <p>
                    <span>Email:&nbsp;&nbsp;</span>sameer@hitechfreak.com
                  </p>
                </li>
              </ul>
            </div>
            <div className="contact__social">
              <p className="contact__linkedin">
                <a
                  href="https://www.linkedin.com/in/sameerjakkali/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
