export const config = {
  portfolio: {
    portfolioLogo: 'Portfolio',
    projectCard: {
      cards: [
        {
          heading: 'Project Management Tool',
          description:
            'Canvas App, SharePoint, Dataverse, Power Automate, Power Bi, Project for web & SQL',
          animationDelay: '300',
          // buttonLink: 'https://codemmit.tech/',
          buttonText: '',
        },
        {
          heading: 'Pricing Change Management',
          description:
            'Canvas App, SharePoint, SQL, Power Automate, Power Bi & Logic Apps',
          animationDelay: '500',
          // buttonLink: 'https://codemmit.tech',
          buttonText: '',
        },
        {
          heading: 'Tech Report Management ',
          description: 'Canvas App, Model Driven App, Dataverse, Azure Blog Storage, Power Automate, Dataflow, Power Bi & SQL ',
          animationDelay: '700',
          // buttonLink: 'https://codemmit.tech',
          buttonText: '',
        },
        {
          heading: 'HRMS',
          description:
            'Canvas App, Dataverse, Power Automate, Dataflow, Power Bi & SQL ',
          animationDelay: '900',
          // buttonLink: 'https://codemmit.tech',
          buttonText: '',
        },
        {
          heading: 'Grievance System',
          description: 'Power Automate, Power Apps, CDS & Power Bi',
          animationDelay: '1100',
          buttonText: '',
        },
        {
          heading: 'Work Order Management',
          description: 'Power Bi',
          animationDelay: '1300',
          buttonText: '',
        },
        {
          heading: 'NICSI - work Automation',
          description: 'Power Automate, Power Apps, SharePoint & Power Bi ',
          animationDelay: '1500',
          buttonText: '',
        },
        {
          heading: 'Bid Automation ',
          description: 'Power Automate, Power Apps, CDS & Power Bi',
          animationDelay: '1700',
          buttonText: '',
        },
        {
          heading: 'Issue Tracker Chatbot',
          description: 'Power Virtual Agents and SharePoint',
          animationDelay: '1900',
          buttonText: '',
        },
      ],
    },
    internships: {
      internship: [
        
        {
          companyName: 'Company Name',
          role: 'Front-end Developer',
          introText: '',
          duration: '10/09/2020 - 10/11/2020',
          keyPointOne:
            'Add few lines here... if requried',
          keyPointTwo: 'Add few lines here... if requried',
          keyPointThree: 'Add few lines here... if requried',
          keyPointFour: '',
          offsetValue: '-1000',
        },
      ],
    },
  },
};

export default config;
