import React from "react";
import { Link } from "react-router-dom";

import "../../styles/About/About.scss";

const About = () => {
  return (
    <>
      <div className="about" id="about">
        <div className="about__container">
          <div className="about__header" id="about__heading">
            <h1 data-aos="fade-down" data-aos-delay="0" data-aos-offset="20">
              <span
                data-aos="fade-down"
                data-aos-delay="0"
                data-aos-offset="200"
              >
                A
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="200"
                data-aos-offset="200"
              >
                B
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-offset="200"
              >
                O
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-offset="200"
              >
                U
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="800"
                data-aos-offset="200"
              >
                T
              </span>
              <span
                data-aos="fade-down"
                data-aos-delay="1000"
                data-aos-offset="200"
              >
                .
              </span>
            </h1>
            <p></p>
          </div>

          <div className="about__details-container">
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="0"
              data-aos-delay="0"
              data-aos-once="true"
            >
              <Link to="/about/intro">
                <h1>
                  0<span>1</span>
                </h1>
                <h2>
                  H<span>ello,</span>
                </h2>
                <p>
                  I am <span>Sameer Jakkali.</span> <br /> A highly passionate
                  Power Platform Developer, Consultant & Architect with more
                  than 6 years of experience.
                </p>
              </Link>
            </div>
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="-180"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <Link to="/#about">
                <h1>
                  0<span>2</span>
                </h1>
                <h2>
                  E<span>xperience</span>
                </h2>
                <p>
                  <span>6 years of experience.</span>
                  <br />I have more than 6 years of experience in designing &
                  developing highly efficient and scalable solutions using
                  Microsoft Power Platform.
                </p>
              </Link>
            </div>
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="50"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <Link to="/#about">
                <h1>
                  0<span>3</span>
                </h1>
                <h2>
                  C<span>ertifications</span>
                </h2>
                <p>
                  <span>Microsoft Certified</span> <br />
                </p>
                <ul>
                  <li>
                    <span>PL-600</span> - Power Platform Solution Architect
                    Expert{" "}
                  </li>
                  <li>
                    <span>PL-400</span> - Power Platform Developer Associate{" "}
                  </li>
                  <li>
                    <span>PL-900</span> - Power Platform Fundamentals{" "}
                  </li>
                  <li>
                    <span>AZ-900</span> - Azure Fundamentals{" "}
                  </li>
                  <li>
                    <span>DP-900</span> - Azure Data Fundamentals{" "}
                  </li>
                </ul>
              </Link>
            </div>
            <div
              className="about__details"
              data-aos="fade-down"
              data-aos-offset="-180"
              data-aos-delay="700"
              data-aos-once="true"
            >
              <Link to="/#about">
                <h1>
                  0<span>4</span>
                </h1>
                <h2>
                  C<span>ollege</span>
                </h2>
                <p>
                  <span>1. Chandigarh University</span>
                  <br />- Masters in Computer Applications
                </p>
                <p>
                  <span>2. Global College of BCA</span>
                  <br />- Bachelor of Computer Applications
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
